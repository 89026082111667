<template>
  <div class="media-body">
    <p class="small text-gray-700 font-weight-normal text-uppercase mb-0">
      {{ heading }}
    </p>
    <p v-if="subHeading" class="h3 mb-0">
      {{ subHeading }}
    </p>
  </div>
</template>

<script>
export default {
  name: "CardTitle",
  props: {
    heading: {
      type: String,
      required: true,
    },
    subHeading: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>

<style scoped></style>
