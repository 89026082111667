export default [
  {
    id: "custom",
    name: "Select an option",
    text: "Choose a custom action",
    component: "",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CommentCreated",
      "scheduler",
    ],
  },
  {
    id: "mail",
    name: "Send an email",
    text: "Send a custom email",
    component: "SendEmail",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CommentCreated",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
      "FieldGroupCreated",
    ],
  },
  {
    id: "send_chatgpt_email",
    name: "Send a ChatGpt email",
    text: "Send a ChatGpt generated email to your customer",
    component: "SendChatGptEmail",
    actions: [
      "OrderCreated",
      "OrderUpdated",
    ],
  },
  {
    id: "sms",
    name: "Send an sms",
    text: "Send an sms to your customer",
    component: "SendSms",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CommentCreated",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
      "FieldGroupCreated",
    ],
  },
  {
    id: "set_field_value",
    name: "Update field value",
    text: "Update a field value for the order",
    component: "SetFieldValue",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
    ],
  },
  {
    id: "add_comment",
    name: "Add order comment",
    text: "Add a comment to the order",
    component: "AddComment",
    actions: ["OrderCreated", "OrderUpdated", "OrderDeleted"],
  },
  {
    id: "status",
    name: "Set status",
    text: "Change the status of the order or customer",
    component: "SetStatus",
    actions: ["OrderCreated", "OrderUpdated", "OrderDeleted"],
  },
  {
    id: "set_tag",
    name: "Set tag",
    text: "Set tag on order",
    component: "SetTag",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
      "FieldGroupCreated",
    ],
  },
  {
    id: "assign_customer",
    name: "Assign to customer",
    text: "Assign this order to an existing customer or create a new one",
    component: "",
    actions: ["OrderCreated", "FieldGroupCreated"],
  },
  {
    id: "enrich_customer",
    name: "Enrich customer",
    text: "Enrich the customer with data from the reply",
    component: "MapFields",
    actions: ["FieldGroupCreated"],
  },
  {
    id: "assign_user",
    name: "Assign to user",
    text: "Assign this to an organization user",
    component: "SelectUser",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
    ],
  },
  {
    id: "assign_store",
    name: "Assign to store",
    text: "Assign this to a store",
    component: "SelectStore",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "CustomerCreated",
      "CustomerUpdated",
    ],
  },
  {
    id: "sync_comment",
    name: "Sync comments  to shop",
    text: "Synchronise comments back to your shop",
    component: "",
    actions: ["CommentCreated"],
  },
  {
    id: "woocommerce_changes",
    name: "Sync changes to shop",
    text: "Synchronise your changes back to your shop",
    component: "",
    actions: ["OrderCreated", "OrderUpdated", "OrderDeleted"],
  },
  {
    id: "run_webhook",
    name: "Run a webhook",
    text: "Send data via a webhook",
    component: "RunWebhook",
    actions: [
      "OrderCreated",
      "OrderUpdated",
      "OrderDeleted",
      "CustomerCreated",
      "CustomerUpdated",
      "CustomerDeleted",
      "FieldGroupCreated",
    ],
  },
  {
    id: "active_campaign_tag",
    name: "Set Active campaign tag",
    text: "Set tag to active campaign contact via a webhook",
    component: "ActiveCampaignTag",
    actions: [
      "OrderCreated",
      "OrderUpdated",
    ]
  }
];
