<template>
  <div class="form-row">
    <div
      v-if="tag.slug !== 'create-new'"
      class="multiselect-wrapper col-12 mb-3 required"
      :class="{ 'is-invalid': errors.has('tag-list') }"
    >
      <label for="tag-list" class="control-label text-capitalize"
        >Select tag</label
      >
      <multiselect
        id="tag-list"
        v-model="tag"
        v-validate="{ required: true }"
        :options="tags"
        track-by="slug"
        label="name"
        placeholder="Select one"
        :show-labels="false"
      />
      <span v-show="errors.has('name')" class="invalid-feedback">{{
        errors.first("tag-list")
      }}</span>
    </div>
    <div v-if="tag.slug !== 'create-new'" class="form-group col-12 mb-10">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save tag configuration'"
        v-on:click.native="submitForm"
      />
    </div>
    <div v-if="tag.slug === 'create-new'" class="col-12 mb-3">
      <div class="form-group">
        <label for="add-tag">Add new tag</label>
        <input
          id="add-tag"
          v-model="new_tag"
          type="text"
          name="to"
          class="form-control"
        />
      </div>
      <div class="form-group">
        <compact-picker :value="color" @input="updateValue" />
      </div>
      <div class="form-group">
        <ButtonLoading
          :loading="loading"
          :id="'add-tag'"
          :name="'add-tag'"
          :class="'btn-primary float-right'"
          :title="'Add tag'"
          :disabled="newTagLoading"
          v-on:click.native="addTag"
        />
      </div>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { Compact } from "vue-color";
import { mapActions, mapGetters } from "vuex";
import { loadFromApi, postToApi } from "@/helpers/apiConnection";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SetTag",
  components: {
    Multiselect,
    "compact-picker": Compact,
    ButtonLoading,
  },
  data() {
    return {
      loading: false,
      newTagLoading: false,
      tag: {
        slug: "",
      },
      tags: [],
      new_tag: "",
      color: "",
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  async mounted() {
    const tags = await loadFromApi("/setup/tag");
    if (tags.length > 0) {
      this.tags = tags;
      if (this.modalConfig && this.modalConfig.tag) {
        this.tag = this.tags.filter(item => {
          return (item.slug === this.modalConfig.tag);
        });
      }
    }
    this.tags.push({
      id: "create-new",
      color: "",
      name: "Create a new tag",
      slug: "create-new",
    });
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.tag = {
        slug: "",
      };
      this.value = "";
    },
    async submitForm() {
      this.loading = true;
      if (this.tag.slug === "") {
        this.$validator.errors.add({
          field: "tag-list",
          msg: "Please select a tag",
        });
        this.loading = false;
      } else {
        this.$validator.validateAll().then((result) => {
          if (result) {
            this.$emit("save", {
              tag: this.tag.slug,
            });
            this.closeModal();
          }
        });
      }
      this.loading = false;
    },
    updateValue(value) {
      this.color = value.hex;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
    async addTag() {
      this.newTagLoading = true;
      function camelToUnderscore(key) {
        var result = key.replace(/([A-Z])/g, "$1");
        return result
          .split(" ")
          .join("_")
          .toLowerCase();
      }
      const res = await postToApi("/tag", {
        name: this.new_tag,
        slug: camelToUnderscore(this.new_tag),
        color: this.color,
      }).catch(() => {
        this.newTagLoading = false;
      });
      this.newTagLoading = false;
      if (res) {
        window.Bus.$emit("flash-message", {
          text: `Tag "${this.new_tag}" added`,
          type: "success",
        });
        await this.$store.dispatch("UPDATE_TAGS", res);
        const addNewTag = this.tags.pop();
        this.tags.push(res);
        this.tags.push(addNewTag);
        this.new_tag = "";
        this.color = "";
        this.tag = res;
        const new_taglist = this.tags.filter(tg => tg.slug !== "create-new");
        this.$store.dispatch("SET_TAGS", new_taglist);
      }
    },
  },
};
</script>

<style>
.color-picker-wrapper {
  display: flex;
}
.color-picker-wrapper label {
  flex: 2;
}
.color-picker-wrapper .color-picker {
  flex: 10;
}
.color-picker-wrapper .color-picker-container .current-color {
  height: 25px;
  width: 25px;
}
.is-invalid .multiselect__tags {
  border-color: #ff4949;
}
.multiselect-wrapper.required .control-label:after {
  content: "*";
  color: red;
  padding-left: 0.3rem;
}
</style>
