<template>
  <div class="form-row">
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('url') }"
    >
      <label for="url" class="control-label text-capitalize"
        >Webhook url:</label
      >
      <input
        id="url"
        v-model="url"
        v-validate="{ required: true }"
        type="text"
        name="subject"
        class="form-control"
        placeholder="https://automation.app"
      />
      <span v-show="errors.has('url')" class="invalid-feedback">{{
        errors.first("url")
      }}</span>
    </div>
    <div
      class="form-group col-md-12"
      :class="{ 'is-invalid': errors.has('signature') }"
    >
      <label for="signature" class="control-label text-capitalize"
        >Webhook signature:</label
      >
      <input
        id="signature"
        v-model="signature"
        v-validate="{ required: false }"
        type="text"
        name="subject"
        class="form-control"
        placeholder="signature-key-of-random-characters"
      />
      <span v-show="errors.has('signature')" class="invalid-feedback">{{
        errors.first("signature")
      }}</span>
      <span class="text-muted"
        >For increased security add a signature key which we will use to sign
        the request.</span
      >
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save webhook configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "RunWebhook",
  components: { ButtonLoading },
  data() {
    return {
      loading: false,
      url: "",
      signature: "",
    };
  },
  computed: {
    ...mapGetters(["modalConfig", "getUser"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.url = "";
      this.signature = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            url: this.url,
            signature: this.signature,
          });
          this.resetModalData();
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.editor {
  max-width: 100%;
}
.editor {
  .editor__content {
    min-height: 100px;
  }
}
</style>
