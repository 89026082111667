<template>
  <ul class="list-group w-100">
    <li class="list-group-item">
      <div class="media position-relative">
        <BoxLightning />
        <div class="media-body">
          <p class="text-muted mb-0">
            When:
          </p>
          <p class="h5 mb-0">
            {{ getEventName(automation.event) }}
          </p>
        </div>
      </div>
      <div class="plus_icon">
        <span class="icon_container">
          <span class="icon_svg">
            <CirclePlus />
          </span>
        </span>
      </div>
    </li>
    <div v-for="(condition, index) in automation.conditions" :key="index">
      <li class="list-group-item">
        <div class="media">
          <BoxPlus />
          <div class="media-body">
            <p class="text-muted mb-0">
              if:
            </p>
            <p class="h5 mb-0">
              {{ conditionTitle(condition) }}
            </p>
          </div>
        </div>
        <div class="condition-group">
          <span
            class="btn btn-outline-primary font-size-sm bg-white line-height-1"
          >
            {{ automation.conditions_group }}
          </span>
        </div>
      </li>
    </div>
    <li class="list-group-item">
      <div class="media">
        <BoxCheck />
        <div class="media-body">
          <p class="text-muted mb-0">
            Do:
          </p>
          <p class="h5 mb-0">
            {{ getActionName(automation.automationAction.key) }}
          </p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import events from "@/helpers/events";
import actions from "@/helpers/actions";
import { getAction, getEvent } from "@/helpers/automationUtilities";
import BoxCheck from "@/components/Svg/BoxCheck.vue";
import BoxLightning from "@/components/Svg/BoxLightning.vue";
import BoxPlus from "@/components/Svg/BoxPlus.vue";
import CirclePlus from "@/components/Svg/CirclePlus.vue";

export default {
  name: "Outline",
  components: {
    BoxCheck,
    BoxLightning,
    BoxPlus,
    CirclePlus,
  },
  props: {
    automation: {
      type: Object,
      required: true,
    },
  },
  methods: {
    conditionTitle: (condition) => {
      if (!condition.field || !condition.value) {
        return "";
      }
      let value = condition.value;
      const fields = ["total", "price"];
      const operators = ["isInRange", "isNotInRange"];
      if (
        fields.includes(condition.field) &&
        operators.includes(condition.operator)
      ) {
        value = (value / 100).toFixed(2);
      }
      return `${condition.type} ${condition.field} ${condition.operator} ${value}`;
    },
    getEventName(event) {
      const eventObj = getEvent(events, event);
      return eventObj.text;
    },
    getActionName(action) {
      const actionObj = getAction(actions, action);
      return actionObj.name;
    },
  },
};
</script>

<style>
.condition-group {
  position: absolute;
  bottom: -10px;
  align-items: center;
  display: flex;
  height: 22px;
  justify-content: center;
  width: calc(100% + 20px);
  margin: 0px -10px;
  z-index: 10;
}
</style>
