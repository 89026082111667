<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label>Field</label>
      <multiselect
        v-model="field"
        :options="fieldOptions"
        group-values="options"
        group-label="title"
        :preselect-first="true"
        :group-select="false"
        placeholder="Type to search"
        track-by="title"
        label="title"
        :custom-label="fieldLabel"
        :show-labels="false"
      >
        <template slot="placeholder">
          <span class="option__desc">
            <span class="option__title">Type to search</span>
          </span>
        </template>
        <template slot="singleLabel" slot-scope="props">
          <span class="option__desc">
            <span class="option__title"
              >{{ props.option.category }} {{ props.option.title }}</span
            >
          </span>
        </template>
        <template slot="option" slot-scope="props">
          <span v-if="props.option.$isLabel">
            {{ props.option.$groupLabel }}
          </span>
          <div v-else class="option__desc">
            <span class="option__title text-dark"
              >{{ props.option.category }} {{ props.option.title }}</span
            >
            <span class="option__small ml-3 text-muted">{{
              props.option.desc
            }}</span>
          </div>
        </template>
        <span slot="noResult">No fields found.</span>
      </multiselect>
    </div>
    <div v-if="field && field.type == 'int'" class="col-12 mb-3">
      <label>Set value</label>
      <input
        id="field-value-number"
        v-model="value"
        v-validate="{ required: true }"
        type="number"
        name="value"
        class="form-control form-control-lg"
        placeholder="Enter value"
      />
    </div>
    <div v-else class="col-12 mb-3">
      <label>Set value</label>
      <input
        id="field-value-string"
        v-model="value"
        v-validate="{ required: true }"
        type="text"
        name="value"
        class="form-control form-control-lg"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save field configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import fields from "@/helpers/fields";
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SetFieldValue",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      field: null,
      fieldOptions: fields,
      value: "",
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    fieldLabel(option) {
      return `${option.category} ${option.title} – ${option.desc}`;
    },
    resetFormData() {
      this.field = null;
      this.fieldOptions = fields;
      this.value = "";
    },
    fieldWithDesc({ title, text }) {
      return `${title} — ${text}`;
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            fieldName: this.field.id,
            value: this.value,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  mounted() {
    if (this.modalConfig) {
      const fields = this.fieldOptions.filter((item) => {
        return item.id === this.modalConfig.fieldName;
      });
      this.field = fields[0];
      this.value = this.modalConfig.value;
    }
  },
};
</script>

<style scoped></style>
