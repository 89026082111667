<template>
  <svg
    width="46"
    height="52"
    viewBox="0 0 42 42"
    class="mr-4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g fill-rule="nonzero" fill="none">
      <path
        d="M7 5h28a2 2 0 012 2v28a2 2 0 01-2 2H7a2 2 0 01-2-2V7a2 2 0 012-2zm14.796 4.255c-.913-.542-2.063-.19-2.57.784l-5.944 11.402A2.51 2.51 0 0013 22.6c0 1.325 1.016 2.4 2.268 2.4h2.964v5.98c0 .733.372 1.41.972 1.765.913.542 2.063.19 2.57-.784l5.944-11.402A2.51 2.51 0 0028 19.4c0-1.325-1.016-2.4-2.268-2.4h-2.964v-5.98c0-.733-.372-1.41-.972-1.765z"
        fill="#DAE7FF"
      />
      <path
        d="M5 0h32a5 5 0 015 5v32a5 5 0 01-5 5H5a5 5 0 01-5-5V5a5 5 0 015-5zm0 2a3 3 0 00-3 3v32a3 3 0 003 3h32a3 3 0 003-3V5a3 3 0 00-3-3H5z"
        fill="#00F"
      />
      <path
        d="M24.35 16.4h2.3c1.856 0 3.35 1.537 3.35 3.42 0 .574-.141 1.139-.411 1.642l-6.42 11.97c-.784 1.47-2.589 2.005-4.019 1.18a3.035 3.035 0 01-1.5-2.633v-5.38h-2.3c-1.856 0-3.35-1.536-3.35-3.419 0-.574.141-1.139.411-1.642l6.42-11.97c.784-1.47 2.589-2.005 4.019-1.18.929.536 1.5 1.545 1.5 2.633v5.38zm-1.843.972V11.2c0-.444-.227-.848-.584-1.056-.526-.307-1.188-.109-1.49.462l-6.24 11.767a1.663 1.663 0 00-.193.778c0 .885.68 1.593 1.507 1.593h3.111a.88.88 0 01.875.884V31.8c0 .444.227.848.584 1.056.526.307 1.188.109 1.49-.462l6.24-11.767c.127-.238.193-.506.193-.778 0-.885-.68-1.593-1.507-1.593h-3.111a.88.88 0 01-.875-.884z"
        fill="#7F7FFF"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BoxLightning",
};
</script>

<style scoped></style>
