<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="store">Select store</label>
      <multiselect
        id="store"
        v-model="store"
        :options="stores"
        track-by="id"
        label="name"
        placeholder="Select one"
        :show-labels="false"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save store configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SelectStore",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      stores: [],
      store: null,
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            store: this.store.id,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    resetFormData() {
      this.status = "";
      this.value = "";
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  async mounted() {
    await this.modalConfig;
    let store;
    if (this.modalConfig && this.modalConfig.store) {
      store = { id: this.modalConfig.store };
    }
    jsonAPI.get("store").then(({ data }) => {
      this.stores = data.data;
      if (!store) {
        this.store = this.stores[0];
      } else {
        this.store = this.stores.filter((item) => {
          return (item.id === store.id);
        })[0];
      }
    });
  },
};
</script>
