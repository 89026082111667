<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="user">Select user</label>
      <multiselect
        id="user"
        v-model="user"
        :options="users"
        track-by="id"
        label="name"
        placeholder="Select one"
        :show-labels="false"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save user configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
// import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SelectUser",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      users: [],
      user: null,
    };
  },
  computed: {
    ...mapGetters(["modalConfig", "getOrganization"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            user: this.user.id,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    resetFormData() {
      this.status = "";
      this.value = "";
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  mounted() {
    if (this.modalConfig && this.modalConfig.user) {
      this.user = { id: this.modalConfig.user };
    }
    this.users = this.getOrganization.users;
    if (!this.user) {
      this.user = this.users[0];
    } else {
      this.user = this.users.filter((item) => {
        return (item.id === this.user.id);
      })[0];
    }

  },
};
</script>
