export default [
  {
    id: "OrderCreated",
    name: "Order created",
    text: "An order is created",
    model: "order",
  },
  {
    id: "OrderUpdated",
    name: "Order updated",
    text: "An order is updated",
    model: "order",
  },
  {
    id: "OrderDeleted",
    name: "Order deleted",
    text: "An order is deleted",
    model: "order",
  },
  {
    id: "CustomerCreated",
    name: "Customer created",
    text: "When a customer is created",
    model: "customer",
  },
  {
    id: "CustomerUpdated",
    name: "Customer updated",
    text: "When a customer is updated",
    model: "customer",
  },
  {
    id: "CustomerDeleted",
    name: "Customer deleted",
    text: "When a customer is deleted",
    model: "customer",
  },
  {
    id: "CommentCreated",
    name: "Comment created",
    text: "A comment is created",
    model: "comment",
  },
  {
    id: "FieldGroupCreated",
    name: "Form reply created",
    text: "A form reply is created",
    model: "fieldGroup",
  },
  {
    id: "FieldGroupUpdated",
    name: "Form reply updated",
    text: "A form reply is updated",
    model: "fieldGroup",
  },
  {
    id: "scheduler",
    name: "Scheduled action",
    text: "Run at intervals",
    model: "order",
  },
];
