<template>
  <div>
    <div class="row fadeInUp mb-12 mt-6">
      <div class="col-12 col-md-10 offset-md-1 col-lg-8 offset-lg-2">
        <div class="card border-0 shadow-sm">
          <div class="card-header rounded">
            <div class="row">
              <div class="col-12 col-md-6">
                <div class="media position-relative">
                  <div v-if="!showEvents" class="css-e2avkx">
                    <span class="css-134uqom">
                      <CheckmarkCircle />
                    </span>
                  </div>
                  <BoxLightning />
                  <div class="media-body">
                    <p
                      class="small text-gray-700 font-weight-normal text-uppercase mb-0"
                    >
                      Trigger
                    </p>
                    <p class="h3 mb-0">
                      {{ form.trigger.text }}
                    </p>
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6">
                <button
                  class="btn btn-outline-secondary btn-lg float-right h-100"
                  @click="showEvents = true"
                >
                  Edit
                </button>
                <button
                  class="btn btn-secondary-soft btn-lg page-intro h-100"
                  @click="runPageIntro"
                >
                  Start page intro
                </button>
              </div>
            </div>
          </div>
          <div v-if="showEvents">
            <div
              class="card-body"
              data-title="Select a trigger for your automation"
              data-intro=""
              data-step="1"
              data-position="bottom"
            >
              <div class="d-flex row flex-row flex-wrap">
                <selectable-element
                  v-for="trigger in triggers"
                  :key="trigger.id"
                  :element="trigger"
                  :active-element="form.trigger"
                  @onUpdateElement="setTrigger(trigger)"
                />
              </div>
            </div>
          </div>
        </div>

        <div v-if="!isTrigger()">
          <ArrowDown />
          <!-- Step 2 of the page intro -->
          <Timing
            :field="{ field: form.delay_field, type: timing.model }"
            :timing-values="timing"
            :delay-value="timing.delay"
            :model="timing.model"
            @timing-set="setTiming"
          />
          <ArrowDown />
        </div>
        <div v-else>
          <ArrowDown />
        </div>
        <!-- Build conditions -->
        <!-- Step 3 of the Page intro -->

        <!-- v-if="!isTrigger()" -->
        <ConditionBtn
          :show="Object.keys(form.conditions).length === 0"
          @newCondition="addCondition"
        />
        <div v-if="form.conditions.length > 1" class="card">
          <div class="card-body text-center">
            <p>Select how the conditions should be evaluated</p>
            <div class="d-flex justify-content-center">
              <button
                class="btn mr-3"
                :class="
                  form.conditions_group === 'AND'
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                "
                @click="form.conditions_group = 'AND'"
              >
                And
              </button>
              <button
                class="btn"
                :class="
                  form.conditions_group === 'OR'
                    ? 'btn-primary'
                    : 'btn-outline-primary'
                "
                @click="form.conditions_group = 'OR'"
              >
                Or
              </button>
            </div>
            <!-- add description when condition is selected -->
            <br />
            <div class="d-flex justify-content-center">
              <div v-if="form.conditions_group === 'AND'">
                <p>All conditions should apply for this automation to run.</p>
              </div>
              <div v-else-if="form.conditions_group === 'OR'">
                <p>Any of these conditions will make the automation run.</p>
              </div>
              <div v-else>
                <p />
              </div>
            </div>
          </div>
        </div>
        <div v-if="form.conditions.length > 1" class="css-12w0090" />
        <!-- eslint-disable vue/no-use-v-if-with-v-for,vue/no-confusing-v-for-v-if -->

        <!-- v-if="!isTrigger()" -->
        <div v-for="(condition, index) in form.conditions" :key="index">
          <Condition
            :condition="condition"
            :index="index"
            :model="model"
            @deleteCondition="deleteCondition"
          />
          <ConditionBtn
            :show="index + 1 === Object.keys(form.conditions).length"
            @newCondition="addCondition"
          />
        </div>
        <ArrowDown />
        <div v-if="isTrigger()">
          <div class="card border-0 shadow-sm">
            <div class="card-header">
              <div class="row">
                <div class="col-12 col-md-8">
                  <div class="media">
                    <BoxPlus />
                    <CardTitle
                      :heading="'Timing'"
                      :sub-heading="'Set an export frequency'"
                    />
                  </div>
                </div>
                <div />
                <div class="col-12 col-md-4">
                  <Delete :mailable="true" @click="deleteCondition(index)" />
                </div>
              </div>
            </div>
            <div class="card-body">
              <div class="form-row mb-3">
                <div class="col-12">
                  <div class="form-row">
                    <div class="form-group col-12">
                      <p class="font-weight-bold mb-0">
                        Set a frequency
                      </p>
                    </div>
                    <!-- <div
                      class="form-group col-12 required"
                      :class="{ 'is-invalid': errors.has('email') }"
                    >
                      <label for="email" class="control-label text-capitalize"
                        >Email to</label
                      >
                      <input
                        id="email"
                        v-model="form.email"
                        v-validate="{ required: true }"
                        type="email"
                        name="email"
                        class="form-control"
                      />
                      <span
                        v-show="errors.has('email')"
                        class="invalid-feedback"
                        >{{ errors.first("email") }}</span
                      >
                    </div> -->
                    <div
                      class="form-group col-md-4 required"
                      :class="{ 'is-invalid': errors.has('frequency') }"
                    >
                      <label
                        for="frequency"
                        class="control-label text-capitalize"
                        >Frequency</label
                      >
                      <select
                        id="frequency"
                        v-model="form.frequency"
                        name="frequency"
                        class="form-control"
                      >
                        <option
                          :key="frequency.id"
                          v-for="frequency in frequencies"
                        >
                          {{ frequency }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('frequency')"
                        class="invalid-feedback"
                        >{{ errors.first("frequency") }}</span
                      >
                    </div>
                    <div
                      v-if="form.frequency !== 'Daily'"
                      class="form-group col-md-4 required"
                      :class="{ 'is-invalid': errors.has('send_on') }"
                    >
                      <label for="send_on" class="control-label text-capitalize"
                        >Send on</label
                      >
                      <select
                        id="send_on"
                        v-model="form.send_on"
                        name="send_on"
                        class="form-control"
                      >
                        <option :key="time" v-for="time in times">
                          {{ time }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('send_on')"
                        class="invalid-feedback"
                        >{{ errors.first("send_on") }}</span
                      >
                    </div>
                    <div
                      class="form-group col-md-4 required"
                      :class="{ 'is-invalid': errors.has('send_at') }"
                    >
                      <label for="send_at" class="control-label text-capitalize"
                        >Send at</label
                      >
                      <select
                        id="send_at"
                        v-model="form.send_at"
                        name="send_at"
                        class="form-control"
                      >
                        <option :key="hour" v-for="hour in hours">
                          {{ hour }}
                        </option>
                      </select>
                      <span
                        v-show="errors.has('send_at')"
                        class="invalid-feedback"
                        >{{ errors.first("send_at") }}</span
                      >
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ArrowDown />
        </div>
        <!-- Action step -->
        <div class="card border-0 shadow-sm mb-8">
          <div class="card-header">
            <div class="row">
              <div class="col-12">
                <div class="media">
                  <BoxCheck />
                  <CardTitle
                    :heading="'Actions'"
                    :sub-heading="'Select the action to execute'"
                  />
                </div>
              </div>
            </div>
          </div>
          <div v-if="form.action">
            <div class="card-body">
              <div
                class="form-group"
                data-title="Select an action to execute"
                data-intro=""
                data-step="4"
              >
                <select
                  v-if="!isTrigger()"
                  v-model="selectedAction"
                  class="form-control form-control-lg"
                >
                  <option
                    v-for="eventActions in getActions"
                    :key="eventActions.id"
                    :value="eventActions"
                    :selected="
                      selectedAction && eventActions.id === selectedAction.id
                        ? 'selected'
                        : ''
                    "
                  >
                    {{ eventActions.name }}
                  </option>
                </select>
                <select
                  v-if="isTrigger()"
                  v-model="selectedAction"
                  class="form-control form-control-lg"
                >
                  <option
                    v-for="triggerAction in triggerActions"
                    :key="triggerAction.id"
                    :value="triggerAction"
                    :selected="
                      selectedAction && triggerAction.id === selectedAction.id
                        ? 'selected'
                        : ''
                    "
                  >
                    {{ triggerAction.name }}
                  </option>
                </select>
              </div>
              <component :is="component" @save="saveConfig" />
            </div>
          </div>
        </div>
        <ButtonLoading
          v-if="showSaveBtn"
          :loading="loading"
          :title="'Save automation'"
          :class="'btn btn-block btn-lg btn-primary'"
          v-on:click.native="save"
        />
      </div>
    </div>
  </div>
</template>

<script>
import "intro.js/minified/introjs.min.css";
import introJs from "intro.js/intro.js";
// Third party
import { mapActions } from "vuex";
// Components
import ArrowDown from "@/components/Automation/ArrowDown.vue";
import CardTitle from "@/components/UI/CardTitle.vue";
import Condition from "@/components/Automation/Condition.vue";
import ConditionBtn from "@/components/Automation/ConditionBtn.vue";
import SelectableElement from "@/components/Automation/SelectableElement.vue";
import Timing from "@/components/Automation/Timing.vue";
import ButtonLoading from "../Form/ButtonLoading.vue";
// SVG icons
import BoxCheck from "@/components/Svg/BoxCheck.vue";
import BoxPlus from "@/components/Svg/BoxPlus.vue";
import BoxLightning from "@/components/Svg/BoxLightning.vue";
import CheckmarkCircle from "@/components/Svg/CheckmarkCircle.vue";
import Delete from "@/components/Svg/Delete.vue";
// Action configs
import SendEmail from "@/components/Automation/ActionConfig/SendEmail.vue";
import SendSms from "@/components/Automation/ActionConfig/SendSms.vue";
import SetFieldValue from "@/components/Automation/ActionConfig/SetFieldValue.vue";
import AddComment from "@/components/Automation/ActionConfig/AddComment.vue";
import SetStatus from "@/components/Automation/ActionConfig/SetStatus.vue";
import SetTag from "@/components/Automation/ActionConfig/SetTag.vue";
import SelectCustomer from "@/components/Automation/ActionConfig/SelectCustomer.vue";
import SelectUser from "@/components/Automation/ActionConfig/SelectUser.vue";
import SelectStore from "@/components/Automation/ActionConfig/SelectStore.vue";
import SelectExport from "@/components/Automation/ActionConfig/SelectExport.vue";
import SendWinBackEmail from "@/components/Automation/ActionConfig/SendWinBackEmail.vue";
import SendChatGptEmail from "@/components/Automation/ActionConfig/SendChatGptEmail.vue";
import RunWebhook from "@/components/Automation/ActionConfig/RunWebhook.vue";
import MapFields from "@/components/Automation/ActionConfig/MapFields.vue";
import ActiveCampaignTag from "@/components/Automation/ActionConfig/ActiveCampaignTag.vue";

import {
  getTriggerEvent,
  getAction,
  getEvent,
} from "@/helpers/automationUtilities";
import { automation } from "@/helpers/defaults";
import events from "@/helpers/events";
import triggerActions from "@/helpers/triggerActions";
import actions from "@/helpers/actions";
import dates from "@/helpers/dates";

export default {
  name: "Automation",
  components: {
    ArrowDown,
    BoxCheck,
    BoxLightning,
    BoxPlus,
    CardTitle,
    CheckmarkCircle,
    Condition,
    ConditionBtn,
    Delete,
    SelectableElement,
    ButtonLoading,
    Timing,
    // Actions
    SendEmail,
    SendSms,
    SetFieldValue,
    AddComment,
    SetStatus,
    SetTag,
    SelectCustomer,
    SelectUser,
    SelectStore,
    SelectExport,
    SendWinBackEmail,
    SendChatGptEmail,
    RunWebhook,
    MapFields,
    ActiveCampaignTag,
  },
  props: {
    automation: {
      type: Object,
      default: () => {
        return Object.assign({}, automation);
      },
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: Object.assign({}, automation),
      step: 0,
      component: null,
      triggers: events,
      model: "",
      selectedAction: null,
      actions: actions,
      triggerActions: actions,
      delay_types: ["Minutes", "Hours", "Days", "Weeks", "Months"],
      frequencies: ["Daily", "Weekly", "Monthly"],
      days: [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ],
      dates: dates,
      showEvents: true,
      showComponentBtn: false,
      showSaveBtn: false,
      modalConfig: {},
      timing: {
        type: "",
        delay: "",
        unit: "",
        operator: "",
        model: "",
        field: "",
      },
    };
  },
  computed: {
    hours() {
      var arr = [],
        i;
      for (i = 7; i < 24; i++) {
        arr.push(i + ":00");
      }
      return arr;
    },
    times() {
      if (this.form.frequency !== "Monthly") {
        return this.days;
      } else {
        return this.dates;
      }
    },
    getActions() {
      return this.actions.filter((item) => {
        return item.actions.includes(this.form.trigger.id);
      });
    },
    configureAction() {
      switch (this.form.action.id) {
        case "add_comment":
          return "Configure comment";
        case "mail":
          return "Configure email";
        case "set_field_value":
          return "Configure field";
        case "status":
          return "Select status";
        case "assign_customer":
          return "Select customer";
        case "assign_user":
          return "Select user";
        default:
          return "Configure action";
      }
    },
    hasAction() {
      return this.form.action.component !== "";
    },
  },
  watch: {
    selectedAction: {
      deep: true,
      handler() {
        this.form.action = this.selectedAction;
        this.component = this.form.action.component;
        let config = this.automation.automationAction.config;
        if (typeof this.automation.automationAction.config == "string") {
          config = JSON.parse(this.automation.automationAction.config);
        }
        this.$store.dispatch("setModalConfig", config);
        if (
          this.selectedAction.id === "woocommerce_changes" ||
          this.selectedAction.id === "assign_customer" ||
          this.selectedAction.id === "sync_comment"
        ) {
          this.showSaveBtn = true;
        }
      },
    },
  },
  mounted() {
    if (this.automation) {
      this.form = this.automation;
      this.form.action = {
        id: "",
        name: "",
        text: "",
        component: "",
      };
      this.timing = {
        type: this.form.delay_type,
        delay: this.form.delay,
        unit: this.form.delay_unit,
        operator: this.form.delay_operator,
        model: this.form.delay_model,
        field: this.form.delay_field,
      };
      if (this.automation.automationAction.config) {
        this.modalConfig = this.automation.automationAction.config;
        this.$store.dispatch("setModalConfig", this.modalConfig);
      }
      if (this.automation.event) {
        this.form.trigger = getTriggerEvent(
          this.triggers,
          this.automation.event
        );
        const event = getEvent(events, this.automation.event);
        this.model = event.model;
        const _actions =
          this.automation.event === "scheduler" ? triggerActions : actions;
        this.selectedAction = getAction(
          _actions,
          this.automation.automationAction.key
        );
        this.component = this.selectedAction.component;
        this.showSaveBtn = true;
        if (
          this.automation.automationAction.config &&
          this.automation.automationAction.config.email
        ) {
          this.form.email = this.automation.automationAction.config.email;
        }
        this.showEvents = false;
      }
      if (this.automation.segment) {
        this.form.segment = this.automation.segment.id;
        this.conditionAction = {
          id: "existingSegment",
          name: "Choose an existing segment",
          text: "",
        };
      }
      if (this.automation.conditions.length) {
        this.conditionAction = {
          id: "setConditions",
          name: "Set condition",
          text: "",
        };
      }
      if(this.form.trigger) {
        this.step = this.step+1;
      }
    }
    // clear conditions input
    if(this.form.trigger.id === 0) {
      this.form.conditions = [];
    }
  },
  methods: {
    ...mapActions(["setModalConfig"]),
    isTrigger() {
      return this.form.trigger.id === "scheduler";
    },
    setTrigger(trigger) {
      this.form.trigger = trigger;
      this.model = trigger.model;
      this.step++;
      this.showEvents = false;
    },
    setTiming(timing) {
      this.form.delay = timing.delay;
      this.form.delay_type = timing.type;
      this.form.delay_unit = timing.unit;
      this.form.delay_operator = timing.operator;
      this.form.delay_model = timing.model;
      this.form.delay_field = timing.field;
    },
    addCondition() {
      if (this.step !== 0 && this.form.trigger.id !== 0) {
        this.form.conditions.push({
          field: "total",
          type: "order",
          operator: "==",
          value: "",
        });
      } else {
        window.Bus.$emit("flash-message", {
          text: "Please choose a trigger",
          type: "error",
        });
      }
    },
    deleteCondition(index) {
      this.$delete(this.form.conditions, index);
    },
    openConfig() {
      let config = this.automation.automationAction.config;
      if (typeof this.automation.automationAction.config == "string") {
        config = JSON.parse(this.automation.automationAction.config);
      }
      this.$store.dispatch("setModalConfig", config);
      this.component = this.form.action.component;
      this.$store.dispatch("openModal", {
        title: this.configureAction,
      });
    },
    saveConfig(config) {
      this.modalConfig = config;
      this.showSaveBtn = true;
    },
    save() {
      const automation = Object.assign({}, this.form);
      automation.event = this.form.trigger.id;
      delete automation.trigger;
      automation.automationAction.key = this.selectedAction.id;
      automation.automationAction.config = this.modalConfig;
      automation.automationAction.config.email = this.form.email;
      this.$emit("save", automation);
    },
    runPageIntro() {
      introJs().start();
    },
  },
};
</script>

<style scoped>
.page-intro {
  margin-right: 20px;
}
</style>
