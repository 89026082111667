<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="status">Enter status</label>
      <multiselect
        id="status"
        v-model="status"
        :options="getStatusList"
        track-by="key"
        label="label"
        placeholder="Select one"
        :show-labels="false"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save status configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SetStatus",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      status: "",
    };
  },
  computed: {
    ...mapGetters(["modalConfig", "getStatusList"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.status = "";
      this.value = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            status: this.status.key,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  async mounted() {
    await this.$store.dispatch("GET_STATUS_LIST");
    if (this.modalConfig) {
      this.status = {key: this.modalConfig.status};
    }

    if (!this.status) {
      this.status= this.getStatusList[0];
    } else {
      this.status = this.getStatusList.filter((item) => {
        return (item.key === this.status.key);
      })[0];
    }
  },
};
</script>
