<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="user">Select form</label>
      <multiselect
        id="form"
        v-model="form"
        :options="forms"
        track-by="id"
        label="name"
        placeholder="Select one"
        :show-labels="false"
      />
    </div>
    <div class="col-12 mb-3" v-if="form">
      <h3>Customer fields</h3>
      <p class="text-muted">
        Select the fields in the form that correspond with fields on the
        customer.
      </p>
      <div class="" v-for="field in customerFields" :key="field.key">
        <label class="control-label text-capitalize">{{ field.label }}</label>
        <select
          :id="field.key"
          value=""
          v-validate="{ required: true }"
          class="form-control form-control-lg"
          required
          name="selected_option"
          @change="selectAnOption"
        >
          <option value="">
            Select an option
          </option>
          <option
            :value="formField.target"
            v-for="formField in form.customFieldConfigs"
            :key="formField.id"
          >
            {{ formField.name }}
          </option>
        </select>
        <span v-show="errors.has('selected_option')" class="invalid-feedback">{{
          errors.first("selected_option")
        }}</span>
      </div>
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save user configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SelectUser",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      forms: [],
      form: null,
      mapping: {},
      customerFields: [
        {
          key: "first_name",
          label: "First name",
        },
        {
          key: "last_name",
          label: "Last name",
        },
        {
          key: "telephone",
          label: "Telephone",
        },
        {
          key: "email",
          label: "Email",
        },
        {
          key: "billing_address.address_line_1",
          label: "Billing street 1",
        },
        {
          key: "billing_address.address_line_2",
          label: "Billing street 2",
        },
        {
          key: "billing_address.postal_code",
          label: "Billing postal code",
        },
        {
          key: "billing_address.locality",
          label: "Billing city",
        },
        {
          key: "billing_address.country",
          label: "Billing country",
        },
        {
          key: "shipping_address.address_line_1",
          label: "Shipping street 1",
        },
        {
          key: "shipping_address.address_line_2",
          label: "Shipping street 2",
        },
        {
          key: "shipping_address.postal_code",
          label: "Shipping postal code",
        },
        {
          key: "shipping_address.locality",
          label: "Shipping city",
        },
        {
          key: "shipping_address.country",
          label: "Shipping country",
        },
      ],
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            mapping: this.mapping,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    resetFormData() {
      this.status = "";
      this.value = "";
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
    selectAnOption(event) {
      const option = event.target.value;
      const field = event.target.id;
      if (option === "") {
        delete this.mapping[field];
        this.resetOption();
      } else {
        this.mapping[field] = option;
      }
    },
  },
  mounted() {
    if (this.modalConfig && this.modalConfig.user) {
      this.user = { id: this.modalConfig.user };
    }
    jsonAPI.get("questionnaire/config").then(({ data }) => {
      this.forms = data.data;
      if (!this.form) {
        this.form = this.forms[0];
      } else {
        this.form = this.forms.filter((item) => {
          return item.id === this.form.id;
        })[0];
      }
    });
  },
};
</script>
