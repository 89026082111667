<template>
  <div class="form-row">
    <div class="form-group col-md-12 required">
      <label class="control-label text-capitalize">Select an option</label>
      <select
        id="to"
        class="form-control form-control-lg"
        required
        @change="selectAnOption"
      >
        <option value="" :selected="option === ''">
          Select an option
        </option>
        <option value="!customer" :selected="option === '!customer'">
          Send to customer
        </option>
        <option value="!shipCustomer" :selected="option === '!shipCustomer'">
          Send to shipping phone
        </option>
        <option value="!billCustomer" :selected="option === '!billCustomer'">
          Send to billing phone
        </option>
        <option value="!me" :selected="option === '!me'">
          Send to me
        </option>
        <option value="option" :selected="option === 'option'">
          Custom input
        </option>
      </select>
    </div>
    <div class="form-group col-md-12">
      <p v-html="option_description" />
    </div>
    <div
      v-if="hideTo"
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('to') }"
    >
      <label for="to" class="control-label text-capitalize">To:</label>
      <input
        id="to"
        v-model="to"
        v-validate="{ required: true }"
        type="tel"
        name="to"
        class="form-control"
        placeholder="+4523632303"
      />
      <span v-show="errors.has('to')" class="invalid-feedback">{{
        errors.first("to")
      }}</span>
    </div>
    <div class="form-group col-md-12 required">
      <label for="content" class="control-label text-capitalize"
        >SMS content:</label
      >
      <Wysiwyg class="editor" :content="initialContent" @content="setContent" />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save SMS configuration'"
        v-on:click.native="submitForm"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <h3>Building SMS messages</h3>
      <p>
        If you need inspiration for how to setup your SMS and populate them with
        variables from your orders or customers.<br />
        <a href="https://automation.app/documentation/objects" target="_blank">Available variables</a>
      </p>
    </div>
  </div>
</template>

<script>
import Wysiwyg from "@/components/Form/Wysiwyg.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SendSms",
  components: {
    ButtonLoading,
    Wysiwyg,
  },
  data() {
    return {
      loading: false,
      hideTo: false,
      to: "",
      option: "",
      content: "",
      initialContent: "{{ comment }}",
      option_description:
        "You can send sms to yourself, a static number or to the client`s number",
    };
  },
  computed: {
    ...mapGetters(["modalConfig", "getUser"]),
  },
  mounted() {
    let conf = this.modalConfig;
    if (conf) {
      this.to = conf.to ? conf.to : "";
      this.option = conf.opt ? conf.opt : "";
      this.content = conf.content ? conf.content : "";
      this.initialContent = conf.content ? conf.content : "";
      this.hideTo = conf.opt === "option";
    }
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    setContent(val) {
      this.content = val;
    },
    selectAnOption(event) {
      const option = event.target.value;
      this.option = option;
      console.log(option);
      switch (option) {
        case "!customer":
          this.setCustomerPhone();
          this.option_description = "Send to the customer`s phone number";
          break;
        case "!shipCustomer":
          this.setShipCustomerPhone();
          this.option_description =
            "Send to the phone number set under the shipping address";
          break;
        case "!billCustomer":
          this.setBillCustomerPhone();
          this.option_description =
            "Send to the phone number set under the billing address";
          break;
        case "!me":
          this.setMyPhone();
          this.option_description =
            "<span>Send to the account owner if a phone number is set in the <a target=\"_blank\" href='/app/account/settings'>account settings</a></span>";
          break;
        case "custom":
          this.setCustomNumber();
          this.option_description = "Enter Custom Number";
          break;
        default:
          this.resetOption();
          break;
      }
    },
    setCustomerPhone() {
      this.hideTo = false;
      this.to = "!customer";
    },
    setShipCustomerPhone() {
      this.hideTo = false;
      this.to = "!shipCustomer";
    },
    setBillCustomerPhone() {
      this.hideTo = false;
      this.to = "!billCustomer";
    },
    setMyPhone() {
      this.hideTo = false;
      this.to = this.getUser.telephone;
    },
    setCustomNumber() {
      this.hideTo = true;
    },
    resetOption() {
      this.to = "";
      this.hideTo = false;
      this.option_description =
        "You can send sms to yourself, a static number or to the client`s number";
    },
    resetFormData() {
      this.to = "";
      this.content = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            to: this.to,
            content: this.content,
            opt: this.option,
          });
          this.resetModalData();
          this.closeModal();
        }
      });
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
.editor {
  max-width: 100%;
}
.editor {
  .editor__content {
    min-height: 100px;
  }
}
</style>
