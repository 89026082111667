<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="customer">Select customer</label>
      <multiselect
        id="customer"
        v-model="customer"
        :options="customers"
        track-by="id"
        :custom-label="fieldWithDesc"
        placeholder="Select one"
        :show-labels="false"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save customer configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import jsonAPI from "@/api";

export default {
  name: "SelectCustomer",
  components: { Multiselect, ButtonLoading },
  data() {
    return {
      loading: false,
      customers: [],
      customer: null,
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    fieldWithDesc({ first_name, last_name }) {
      return `${first_name} ${last_name}`;
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            customer: this.customer.id,
          });
          this.loading = false;
          this.closeModal();
        }
      });
      this.loading = false;
    },
    resetFormData() {
      this.status = "";
      this.value = "";
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  mounted() {
    if (this.modalConfig && this.modalConfig.customer) {
      this.customer = { id: this.modalConfig.customer };
    }
    jsonAPI.get("customer?per_page=50").then(({ data }) => {
      this.customers = data.data;
      if (!this.customer) {
        this.customer = this.customers[0];
      } else {
        this.customer = this.customers.filter((item) => {
          return (item.id === this.customer.id);
        })[0];
      }
    });
  },
};
</script>
