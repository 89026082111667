<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="46"
    height="52"
    class="mr-4"
    viewBox="0 0 42 42"
  >
    <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
      <rect
        stroke="#00F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        width="40"
        height="40"
        rx="4"
      />
      <rect fill="#DAE7FF" x="4" y="4" width="32" height="32" rx="2" />
      <path
        d="M12 11.253v-.437C12 9.813 12.813 9 13.816 9h12.368C27.187 9 28 9.813 28 10.816v.437a4 4 0 01-.85 2.464l-4.34 5.55a2 2 0 000 2.465l4.34 5.55a4 4 0 01.85 2.465v.437A1.816 1.816 0 0126.184 32H13.816A1.816 1.816 0 0112 30.184v-.437a4 4 0 01.85-2.464l4.34-5.55a2 2 0 000-2.465l-4.34-5.55a4 4 0 01-.85-2.465z"
        stroke="#807FFF"
        stroke-width="2"
        fill="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        stroke="#7F7FFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M15 28.5h10"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BoxDelay",
};
</script>

<style scoped></style>
