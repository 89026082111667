<template>
  <div class="form-row">
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('url') }"
    >
        <label for="url" class="control-label text-capitalize">
            Webhook url:
        </label>
        <input
            id="url"
            v-model="url"
            v-validate="{ required: true }"
            type="text"
            name="url"
            class="form-control"
            placeholder="https://automation.app"
        />
        <span v-show="errors.has('url')" class="invalid-feedback">
            {{ errors.first("url") }}
        </span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('api_key') }"
    >
        <label for="api_key" class="control-label text-capitalize">
            Api-key:
        </label>
        <input
            id="api_key"
            v-model="api_key"
            v-validate="{ required: true }"
            type="text"
            name="api_key"
            class="form-control"
            placeholder="copy api-key from from active campaign"
        />
        <span v-show="errors.has('api_key')" class="invalid-feedback">
            {{ errors.first("api_key") }}
        </span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('tag') }"
    >
        <label for="tag" class="control-label text-capitalize">
            Tag:
        </label>
        <input
            id="tag"
            v-model="tag"
            v-validate="{ required: true }"
            type="text"
            name="tag"
            class="form-control"
            placeholder="enter tag name"
        />
        <span v-show="errors.has('tag')" class="invalid-feedback">
            {{ errors.first("tag") }}
        </span>
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save active campaign configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "ActiveCampaignTag",
  components: { ButtonLoading },
  data() {
    return {
      loading: false,
      url: "",
      api_key: "",
      tag: ""
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.url = "";
      this.api_key = "";
      this.tag = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            url: this.url,
            api_key: this.api_key,
            tag: this.tag,
          });
          this.resetModalData();
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  async mounted() {
    await this.modalConfig;
    if(this.modalConfig && this.modalConfig.url) {
        this.url = this.modalConfig.url;
        this.api_key = this.modalConfig.api_key;
        this.tag = this.modalConfig.tag;
    }
  }
};
</script>

<style lang="scss">
.editor {
  max-width: 100%;
}
.editor {
  .editor__content {
    min-height: 100px;
  }
}
</style>
