<template>
  <div class="text-center">
    <div class="css-12w0090" />
    <div aria-hidden="true" tabindex="-1" class="css-eaceof">
      ▼
    </div>
  </div>
</template>

<script>
export default {
  name: "ArrowDown",
};
</script>

<style scoped></style>
