<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <label for="comment">Comment</label>
      <textarea
        id="comment"
        v-model="comment"
        v-validate="{ required: true }"
        class="form-control"
        rows="12"
        placeholder="Write your comment here"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save comment configuration'"
        v-on:click.native="submitForm"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <h3>Building comment</h3>
      <p>
        If you need inspiration for how to construct your message and populate
        them with variables from your orders or customers.<br />
        <a href="https://automation.app/documentation/objects" target="_blank">Available variables</a>
      </p>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "AddComment",
  components: {
    ButtonLoading,
  },
  data() {
    return {
      loading: false,
      comment: "",
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.comment = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            comment: this.comment,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
  mounted() {
    if (this.modalConfig && this.modalConfig.comment) {
      this.comment = this.modalConfig.comment;
    }
  },
};
</script>
