<template>
  <div class="o-field c-input-tiptap is-limit-height is-inverted">
    <div class="o-field__input-wrapper">
      <div class="c-input-tiptap__input">
        <div class="c-rich-text-editor__header">
          <editor-menu-bar
            v-slot="{
              commands,
              isActive,
              getMarkAttrs,
            }"
            :editor="editor"
          >
            <div class="c-input-tiptap__toolbar menubar">
              <form
                v-if="linkMenuIsActive"
                class="menububble__form"
                @submit.prevent="setLinkUrl(commands.link, linkUrl)"
              >
                <input
                  ref="linkInput"
                  v-model="linkUrl"
                  class="menububble__input"
                  type="text"
                  placeholder="https://"
                  @keydown.esc="hideLinkMenu"
                />
                <button
                  class="menububble__button"
                  type="button"
                  @click="setLinkUrl(commands.link, null)"
                >
                  set
                </button>
              </form>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bold() }"
                @click="commands.bold"
              >
                <i class="fas fa-bold" />
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.italic() }"
                @click="commands.italic"
              >
                <i class="fas fa-italic" />
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.underline() }"
                @click="commands.underline"
              >
                <i class="fas fa-underline" />
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.link() }"
                @click="showLinkMenu(getMarkAttrs('link'))"
              >
                <i class="fas fa-link" />
              </button>
              <button
                class="menubar__button"
                :class="{
                  'is-active': isActive.heading({ level: 2 }),
                }"
                @click="commands.heading({ level: 2 })"
              >
                <i class="fas fa-heading" />
              </button>
              <button
                class="menubar__button"
                :class="{ 'is-active': isActive.bullet_list() }"
                @click="commands.bullet_list"
              >
                <i class="fas fa-list-ul" />
              </button>
            </div>
          </editor-menu-bar>
          <div class="c-rich-text-editor__content o-text">
            <editor-content class="editor__content" :editor="editor" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Editor, EditorContent, EditorMenuBar } from "tiptap";
import {
  Blockquote,
  BulletList,
  CodeBlock,
  HardBreak,
  Heading,
  ListItem,
  OrderedList,
  TodoItem,
  TodoList,
  Bold,
  Code,
  Italic,
  Link,
  Strike,
  Underline,
  History,
  Placeholder,
} from "tiptap-extensions";

export default {
  components: {
    EditorContent,
    EditorMenuBar,
  },
  props: ["content"],
  data() {
    return {
      editor: new Editor({
        extensions: [
          new Blockquote(),
          new BulletList(),
          new CodeBlock(),
          new HardBreak(),
          new Heading({ levels: [1, 2, 3] }),
          new ListItem(),
          new OrderedList(),
          new TodoItem(),
          new TodoList(),
          new Link(),
          new Bold(),
          new Code(),
          new Italic(),
          new Strike(),
          new Underline(),
          new History(),
          new Placeholder({
            emptyEditorClass: "is-editor-empty",
            emptyNodeClass: "is-empty",
            emptyNodeText: "Write something …",
            showOnlyWhenEditable: true,
            showOnlyCurrent: true,
          }),
        ],
        onUpdate: ({ getJSON, getHTML }) => {
          this.json = getJSON();
          this.html = getHTML();
        },
        content: `
                  <p></p>
                `,
      }),
      linkUrl: null,
      linkMenuIsActive: false,
      json: null,
      html: null,
      query: null,
      suggestionRange: null,
      filteredUsers: [],
      navigatedUserIndex: 0,
      insertMention: () => {
        return {};
      },
      observer: null,
    };
  },
  watch: {
    html: function(val) {
      this.$emit("content", val);
    },
  },
  mounted() {
    this.$nextTick(function() {
      this.editor.setContent(this.content);
    });
  },
  beforeDestroy() {
    this.editor.destroy();
  },
  methods: {
    showLinkMenu(attrs) {
      this.linkUrl = attrs.href;
      this.linkMenuIsActive = true;
      this.$nextTick(() => {
        this.$refs.linkInput.focus();
      });
    },
    hideLinkMenu() {
      this.linkUrl = null;
      this.linkMenuIsActive = false;
    },
    setLinkUrl(command, url) {
      command({ href: url });
      this.hideLinkMenu();
    },
  },
};
</script>
