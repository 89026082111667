<template>
  <div class="col mb-2">
    <div
      class="element p-3"
      :class="[getType(), { edTR: this.activeElement.id === element.id }]"
      tabindex="0"
      @click="updateActiveElement"
      @keydown.space="updateActiveElement"
    >
      <div
        class="d-flex justify-content-between align-items-center position-relative mb-2"
      >
        <label>
          <div class="element_title">{{ element.name }}</div>
        </label>
        <div
          role="checkbox"
          :class="[
            'checkbox',
            { checked: this.activeElement.id === element.id },
          ]"
        />
      </div>
      <p class="text-muted small mb-0">
        {{ element.text }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  model: {
    prop: "activeElement",
    event: "onUpdateElement",
  },
  props: {
    element: {
      type: Object,
      required: true,
    },
    activeElement: {
      type: Object,
      required: true,
    },
  },
  methods: {
    updateActiveElement() {
      this.$emit("onUpdateElement", this.element.id);
    },
    getType() {
      switch (this.element.id) {
        case "OrderCreated":
        case "CustomerCreated":
          return "created";
        case "OrderDeleted":
        case "CustomerDeleted":
          return "deleted";
        case "OrderUpdated":
        case "CustomerUpdated":
          return "updated";
        case "CommentCreated":
          return "created";
        default:
          return "trigger";
      }
    },
  },
};
</script>

<style scoped>
.col {
  flex: 1 1 33.33%;
}
</style>
