<template>
  <div class="form-row">
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('delay') }"
    >
      <label for="delay" class="control-label text-capitalize"
        >Delay in days:</label
      >
      <input
        id="delay"
        v-model="delay"
        v-validate="{ required: true }"
        type="number"
        name="to"
        class="form-control"
      />
      <span v-show="errors.has('delay')" class="invalid-feedback">{{
        errors.first("delay")
      }}</span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('subject') }"
    >
      <label for="subject" class="control-label text-capitalize"
        >Subject:</label
      >
      <input
        id="subject"
        v-model="subject"
        v-validate="{ required: true }"
        type="text"
        name="subject"
        class="form-control"
      />
      <span v-show="errors.has('subject')" class="invalid-feedback">{{
        errors.first("subject")
      }}</span>
    </div>
    <div class="form-group col-md-12 required">
      <label for="content" class="control-label text-capitalize"
        >Email content:</label
      >
      <Wysiwyg
        id="content"
        class="editor"
        :content="initialContent"
        @content="setContent"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save email configuration'"
        @click="submitForm"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <h3>Building emails</h3>
      <p>
        If you need inspiration for how to setup your emails and populate them
        with variables from your orders or customers.<br />
        <a href="https://automation.app/documentation/twig" target="_blank"
          >Using variables in emails</a
        ><br />
        <a href="https://automation.app/documentation/objects" target="_blank">Available variables</a>
      </p>
    </div>
  </div>
</template>

<script>
import Wysiwyg from "@/components/Form/Wysiwyg.vue";
import { mapActions, mapGetters } from "vuex";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SendEmail",
  components: {
    ButtonLoading,
    Wysiwyg,
  },
  data() {
    return {
      delay: "",
      loading: false,
      subject: "",
      content: "",
      initialContent: "",
    };
  },
  mounted() {
    let conf = this.modalConfig;
    if (conf) {
      this.delay = conf.delay ? conf.delay : "";
      this.subject = conf.subject ? conf.subject : "";
      this.content = conf.content ? conf.content : "";
      this.initialContent = conf.content ? conf.content : "";
    }
  },
  computed: {
    ...mapGetters(["modalConfig", "getUser"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    setContent(val) {
      this.content = val;
    },
    resetFormData() {
      this.delay = "";
      this.subject = "";
      this.content = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            delay: this.delay,
            subject: this.subject,
            content: this.content,
          });
          this.resetModalData();
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
  },
};
</script>

<style lang="scss">
.editor {
  max-width: 100%;
}
.editor {
  .editor__content {
    min-height: 100px;
  }
}
</style>
