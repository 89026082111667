<template>
  <div class="form-row">
    <div class="col-12 mb-3">
      <h3 for="comment">
        Select export
      </h3>
      <DataTable :headers="headers()" sort-url="/exports" :data="exports.data">
        <template #order_number="{ item }">
          {{ item.name }}
        </template>
        <template #actions="{ item }">
          <button
            v-bind:class="{'btn-success':item.id===activeexport}"
            class="btn btn-sm btn-secondary"
            @click="selectExport(item.id)"
          >
            Select
          </button>
        </template>
      </DataTable>
      <pagination
        :data="exports"
        :limit="3"
        :show-disabled="true"
        align="right"
        @pagination-change-page="getExports"
      >
        <span slot="prev-nav" class="text-capitalize"
          ><i class="fas fa-chevron-left mr-2" /> Prev</span
        >
        <span slot="next-nav" class="text-capitalize"
          >Next<i class="fas fa-chevron-right ml-2"
        /></span>
      </pagination>
      <div v-if="exports.data.length == 0" class="mt-3">
        <p class="text-muted">
          No exports found
        </p>
        <router-link
          :to="{ name: 'export.index' }"
          class="btn btn-outline-primary"
        >
          Create export
        </router-link>
      </div>
    </div>
    <div
      class="form-group col-12 required"
      :class="{ 'is-invalid': errors.has('email') }"
    >
      <label for="email" class="control-label text-capitalize">Email to</label>
      <input
        id="email"
        v-model="email"
        v-validate="{ required: true }"
        type="email"
        name="email"
        class="form-control"
      />
      <span v-show="errors.has('email')" class="invalid-feedback">{{
        errors.first("email")
      }}</span>
    </div>
    <div class="form-group col-md-12">
      <label for="templates" class="control-label text-capitalize"
        >Select View:</label
      >
      <Multiselect
        v-model="selected_view"
        track-by="id" 
        label="name"
        :options="views"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        placeholder="Select a view"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <p>
        <router-link
          :to="{ name: 'configuration.view' }"
        >
          Create View
        </router-link>
      </p>
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save export configuration'"
        v-on:click.native="submitForm"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import DataTable from "@/components/DataTable/DataTable.vue";
import Multiselect from "vue-multiselect";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";
import jsonAPI from "@/api";

export default {
  name: "SelectExport",
  components: {
    DataTable,
    ButtonLoading,
    Multiselect
  },
  data() {
    return {
      loading: false,
      export: "",
      activeexport: "",
      email: "",
      selected_view: "",
      views: [],
      exports: {
        data: [],
        meta: {
          total: 0,
        },
        links: {},
      },
    };
  },
  computed: {
    ...mapGetters(["modalConfig"]),
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    resetFormData() {
      this.export = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            export: this.export,
            to: this.email,
            view: this.selected_view.id,
          });
          this.closeModal();
        }
      });
      this.loading = false;
    },
    selectExport(id) {
      this.export = id;
      this.activeexport = id;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
    headers() {
      return [
        { title: "Name", key: "name", sortable: true, sortkey: "name" },
        { title: "Actions", key: "actions" },
      ];
    },
    getExports: function(page = 1) {
      let uri = "/exports?page=" + page;

      if (this.$route.query.sort && this.$route.query.dir) {
        uri +=
          "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
      }

      jsonAPI
        .get(uri)
        .then((response) => {
          this.exports = response.data;
        })
        .catch(() => {
          window.Bus.$emit("flash-message", {
            text: "Error fetching results",
            type: "error",
          });
        });
    },
  },
  mounted() {
    if (this.modalConfig) {
      this.export = this.modalConfig.export;
    }
    let uri = "/exports?page=1";

    if (this.$route.query.sort && this.$route.query.dir) {
      uri +=
        "&sort=" + this.$route.query.sort + "&dir=" + this.$route.query.dir;
    }

    jsonAPI
      .get(uri)
      .then((response) => {
        this.exports = response.data;
      })
      .catch(() => {
        window.Bus.$emit("flash-message", {
          text: "Error fetching results",
          type: "error",
        });
      });

    jsonAPI
      .get('/configuration/view?page=1')
      .then(response => {
        const view_data = response.data.data;
        const views_array = [];
        view_data.map(function(value) {
          // create array for multiselect
          views_array.push({
            "id": value.id,
            "organization_id": value.organization_id,
            "owner_id": value.owner_id,
            "name": value.data.name
          })
        });
        this.views = views_array;
      })
      .catch(() => {
        window.Bus.$emit("flash-message", {
          text: "Error fetching results",
          type: "error",
        });
      })
  },
};
</script>
