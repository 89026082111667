export default [
    {
        id: 'export',
        name: 'Export report',
        text: 'Periodically send report',
        component: 'SelectExport'
    },
    {
        id: 'send_win_back_email',
        name: 'Send email',
        text: 'Send a message to customers who haven\'t placed an order within a given period.',
        component: 'SendWinBackEmail'
    }
];