interface Event {
  id: string;
  name: string;
  text: string;
  model: string;
}
interface Action {
  id: string;
  name: string;
  text: string;
  component: string;
  actions: Array<string>;
}
export const getTriggerEvent = (triggers: Array<Event>, name: string) => {
    const event = triggers.filter(elem => {
        return elem.id === name;
    });
    return event[0];
}

export const getAction = (actions: Array<Action>, key: string) => {
    const action = actions.filter(elem => {
        return elem.id === key;
    });
    return action[0];
}

export const getEvent = (events: Array<Event>, key: string) => {
    const event = events.filter(elem => {
        return elem.id === key;
    });
    return event[0];
}

export const EventName = (events: Array<Event>, event_id: string) => {
    const event = getEvent(events, event_id);
    if (event) {
        return event.name;
    }
    return event_id;
}