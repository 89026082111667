<template>
  <svg
    width="48"
    height="52"
    class="mr-3"
    viewBox="0 0 48 48"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
      <rect
        stroke="#00F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        width="40"
        height="40"
        rx="4"
      />
      <rect fill="#DAE7FF" x="4" y="4" width="32" height="32" rx="2" />
      <rect
        stroke="#807FFF"
        stroke-width="2"
        fill="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        x="10"
        y="10"
        width="20"
        height="20"
        rx="2"
      />
      <path
        stroke="#7F7FFF"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        d="M20 15v10M15 20h10"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BoxPlus",
};
</script>

<style scoped></style>
