export const automation = {
    trigger: {
        id: 0,
        name: "Select an option",
        text: "When this happens..."
    },
    conditions: [],
    delay: 0,
    delay_type: 'time_delay',
    delay_unit: 'Minutes',
    delay_operator: '-',
    delay_model: '',
    delay_field: '',
    event: '',
    send_on: '',
    send_at: '',
    frequency: '',
    conditions_group: 'AND',
    segment: '',
    automationAction: {}
}