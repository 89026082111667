<template>
  <div class="card border-0 shadow-sm">
    <div class="card-header rounded">
      <div class="row">
        <div class="col-12 col-md-10">
          <div class="media position-relative">
            <div class="css-e2avkx">
              <span class="css-134uqom">
                <CheckmarkCircle />
              </span>
            </div>
            <BoxDelay />
            <CardTitle :heading="'Timing'" :sub-heading="getSubHeading" />
          </div>
        </div>
        <div
          class="col-12 col-md-2"
          data-title="Set a timing for your automation"
          data-intro=""
          data-step="2"
          data-position="left"
        >
          <button
            class="btn btn-outline-secondary btn-lg float-right h-100"
            @click="hasDelay = !hasDelay"
          >
            {{ hasDelay ? "Close" : "Edit" }}
          </button>
        </div>
      </div>
    </div>
    <div v-if="hasDelay" class="card-body">
      <div class="d-flex row flex-row flex-wrap mb-3">
        <selectable-element
          v-for="option in options"
          :key="option.id"
          :element="option"
          :active-element="selectedOption"
          @onUpdateElement="setOption(option)"
        />
      </div>

      <div v-if="selectedOption.id" class="form-row">
        <div v-if="selectedOption.id === 'field_delay'" class="form-row">
          <div class="form-group col-md-12">
            <label for="field-select">Select field</label>
            <FieldSelect
              id="field-select"
              :condition="field"
              :type="'date'"
              :model="model"
              @field-selected="setField"
            />
          </div>
          <div class="form-group col-md-4">
            <label for="delay">Time delayed for (value)</label>
            <input
              id="delay"
              v-model="delay"
              type="number"
              class="form-control"
              min="0"
            />
            <small id="delay-description" class="form-text text-muted"
              >Use a number (decimals are not accepted).</small
            >
          </div>
          <div class="form-group col-md-4">
            <label for="delay-type">Time delayed for (unit)</label>
            <multiselect
              id="delay-type"
              v-model="delay_unit"
              :options="delay_units"
              :allow-empty="false"
              :show-labels="false"
            />
            <small id="delay-type-description" class="form-text text-muted"
              >Choose the unit (minutes, hours, days, weeks, months).</small
            >
          </div>
          <div class="form-group col-md-4">
            <label for="delay">Select delay operator</label>
            <select
              id="delay_operator"
              v-model="delay_operator"
              type="number"
              class="form-control"
            >
              <option value="-">
                Before
              </option>
              <option value="+">
                After
              </option>
            </select>
            <small id="delay_operator_description" class="form-text text-muted"
              >Choose to act before or after the field date.</small
            >
          </div>
        </div>
        <div v-else class="row col-md-12">
          <div class="form-group col-md-6">
            <label for="delay">Time delayed for (value)</label>
            <input
              id="delay"
              v-model="delay"
              type="number"
              class="form-control"
              min="0"
            />
            <small id="delay_description" class="form-text text-muted"
              >Use a number (decimals are not accepted).</small
            >
          </div>
          <div class="form-group col-md-6">
            <label for="delay_type">Time delayed for (unit)</label>
            <multiselect
              id="delay_type"
              v-model="delay_unit"
              :options="delay_units"
              :allow-empty="false"
              :show-labels="false"
            />
            <small id="delay_type_description" class="form-text text-muted"
              >Choose the unit (minutes, hours, days, weeks, months).</small
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import Multiselect from "vue-multiselect";
import SelectableElement from "@/components/Automation/SelectableElement.vue";
import BoxDelay from "@/components/Svg/BoxDelay.vue";
import CheckmarkCircle from "@/components/Svg/CheckmarkCircle.vue";
import FieldSelect from "@/components/UI/FieldSelect.vue";
import CardTitle from "@/components/UI/CardTitle.vue";

export default {
  name: "Timing",
  components: {
    Multiselect,
    BoxDelay,
    CheckmarkCircle,
    SelectableElement,
    FieldSelect,
    CardTitle,
  },
  props: {
    field: {
      type: Object,
      required: true,
    },
    timingValues: {
      type: Object,
      required: false,
      default: () => {
        return {
          type: "time_delay",
          delay: 0,
          unit: "Minutes",
          operator: "-",
          model: "",
          field: "",
        };
      },
    },
    model: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      delay: Vue.util.extend({}, this.timingValues).delay,
      delay_unit: Vue.util.extend({}, this.timingValues).unit,
      delay_operator: Vue.util.extend({}, this.timingValues).operator,
      hasDelay: false,
      options: [
        {
          id: "time_delay",
          name: "Time delay",
          text: "The delay should happen at the set time.",
        },
        {
          id: "field_delay",
          name: "Field delay",
          text: "The delay is relative to a time in a field.",
        },
      ],
      selectedOption: {
        id: Vue.util.extend({}, this.timingValues).type,
        name: "Select an option",
        text: "",
      },
      delay_units: ["Minutes", "Hours", "Days", "Weeks", "Months"],
      selectedField: {
        id: Vue.util.extend({}, this.timingValues).field,
        model: Vue.util.extend({}, this.timingValues).model,
        type: Vue.util.extend({}, this.timingValues).type,
      },
      timing: {
        type: "",
        delay: "",
        unit: "",
        operator: "",
        model: "",
        field: "",
      },
    };
  },
  computed: {
    getSubHeading() {
      return this.delay > 0
        ? "Delay for " + this.delay + " " + this.delay_unit.toLowerCase()
        : "No delay";
    },
  },
  watch: {
    delay() {
      this.valuesUpdated();
    },
    delay_unit() {
      this.valuesUpdated();
    },
    delay_operator() {
      this.valuesUpdated();
    },
    timingValues(v) {
      const timing = Object.assign({}, v);
      this.delay = timing.delay;
      this.delay_unit = timing.unit;
      this.delay_operator = timing.operator;
      this.selectedOption.id = timing.type;
      this.selectedField.id = timing.field ?? this.selectedField.field;
      this.selectedField.type = timing.type;
      if (this.delay > 0) {
        this.hasDey = true;
      }
    },
  },
  mounted() {
    this.selectedField = {
      id: this.timing.field,
      model: this.timing.model,
      type: this.timing.type,
    };
  },
  methods: {
    setOption(trigger) {
      this.selectedOption = trigger;
      this.valuesUpdated();
    },
    setField(field) {
      this.selectedField = field;
      this.valuesUpdated();
    },
    valuesUpdated() {
      this.timing.type = this.selectedOption.id;
      this.timing.delay = parseInt(this.delay);
      this.timing.unit = this.delay_unit;
      this.timing.operator = this.delay_operator;
      this.timing.field = this.selectedField.id;
      this.timing.model = this.selectedField.model;
      this.$emit("timing-set", this.timing);
    },
  },
};
</script>

<style scoped></style>
