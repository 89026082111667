<template>
  <div class="form-row">
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('selected_option') }"
    >
      <label class="control-label text-capitalize">Select an option</label>
      <select
        id="selected_option"
        v-model="selected_option"
        v-validate="{ required: true }"
        class="form-control form-control-lg"
        required
        name="selected_option"
        @change="selectAnOption"
      >
        <option value="">
          Select an option
        </option>
        <option value="to_customer">
          Send to customer
        </option>
        <option value="to_me">
          Send to me
        </option>
        <option value="custom">
          Custom input
        </option>
      </select>
      <span v-show="errors.has('selected_option')" class="invalid-feedback">{{
        errors.first("selected_option")
      }}</span>
    </div>
    <div class="form-group col-md-12">
      <p v-html="option_description" />
    </div>
    <div
      v-if="hideTo"
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('to') }"
    >
      <label for="to" class="control-label text-capitalize">To:</label>
      <input
        id="to"
        v-model="to"
        v-validate="{ required: true }"
        type="email"
        name="to"
        class="form-control"
        placeholder="email@example.com"
      />
      <span v-show="errors.has('to')" class="invalid-feedback">{{
        errors.first("to")
      }}</span>
    </div>
    <div
      class="form-group col-md-12 required"
      :class="{ 'is-invalid': errors.has('subject') }"
    >
      <label for="subject" class="control-label text-capitalize"
        >Subject:</label
      >
      <input
        id="subject"
        v-model="subject"
        v-validate="{ required: true }"
        type="text"
        name="subject"
        class="form-control"
        placeholder="Email subject"
      />
      <span v-show="errors.has('subject')" class="invalid-feedback">{{
        errors.first("subject")
      }}</span>
    </div>
    <div class="form-group col-md-12 required">
      <label for="content" class="control-label text-capitalize"
        >Email content:</label
      >
      <Wysiwyg
        :id="content"
        class="editor"
        :content="initialContent"
        @content="setContent"
      />
    </div>
    <div class="form-group col-md-12 required">
      <label for="templates" class="control-label text-capitalize"
        >Template to use:</label
      >
      <Multiselect
        :id="'templates'"
        v-model="template_option"
        :invalid="errors.has('templates')"
        :options="templateKeys"
        :searchable="true"
        :allow-empty="true"
        :show-labels="false"
        @input="onChangeTemplate"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <ButtonLoading
        :loading="loading"
        :class="'btn-primary float-right'"
        :title="'Save email configuration'"
        v-on:click.native="submitForm"
      />
    </div>
    <div class="form-group col-12 mb-0">
      <h3>Building emails</h3>
      <p>
        If you need inspiration for how to setup your emails and populate them
        with variables from your orders or customers.<br />
        <a href="https://automation.app/documentation/twig" target="_blank"
          >Using variables in emails</a
        ><br />
        <a href="https://automation.app/documentation/objects" target="_blank">Available variables</a>
      </p>
    </div>
  </div>
</template>

<script>
import Multiselect from "vue-multiselect";
import Wysiwyg from "@/components/Form/Wysiwyg.vue";
import { mapActions, mapGetters } from "vuex";
import jsonAPI from "@/api";
import ButtonLoading from "@/components/Form/ButtonLoading.vue";

export default {
  name: "SendEmail",
  components: {
    Multiselect,
    ButtonLoading,
    Wysiwyg,
  },
  data() {
    return {
      loading: false,
      hideTo: false,
      sendToCustomer: false,
      sendToMe: false,
      to: "",
      subject: "",
      content: "",
      initialContent: "",
      templateKeys: [],
      templates: [],
      template_option: "",
      selectedTemplate: "",
      empty_template: "Create your first template, click here",
      selected_option: "",
      option_description:
        "You can send an email to yourself, a static email or to the customer`s email",
    };
  },
  computed: {
    ...mapGetters(["modalConfig", "getUser"]),
  },
  mounted() {
    let conf = this.modalConfig;
    if (conf) {
      this.to = conf.to ? conf.to : "";
      this.subject = conf.subject ? conf.subject : "";
      this.content = conf.content ? conf.content : "";
      this.initialContent = conf.content ? conf.content : "";
      this.selected_option = "custom";
      if (this.to === "!customer") {
        this.setCustomerEmail();
        this.selected_option = "to_customer";
      }
      if (this.to === this.getUser.email) {
        this.setMyEmail();
        this.selected_option = "to_me";
      }
    }
    jsonAPI.get("/configuration/template?per_page=100").then((response) => {
      if (response.data.data.length == 0) {
        this.templates = [this.empty_template];
        this.templateKeys = [this.empty_template];
      } else {
        this.templates = response.data.data;
        this.templateKeys = response.data.data.map((item) => {
          return item.data.name;
        });

        if (this.templates) {
          this.template_option = this.templates[0].name;
          this.selectedTemplate = this.templates[0].id;
          if (conf) {
            const template = this.templates.find((item) => {
              return item.id === conf.template;
            });
            if (template && template.data) {
              this.template_option = template.data.name;
            }
          }
        } else {
          this.templateKeys.push("No templates available");
        }
      }
    });
  },
  methods: {
    ...mapActions(["setModalConfig", "closeModal", "resetModalData"]),
    setContent(val) {
      this.content = val;
    },
    selectAnOption(event) {
      const option = event.target.value;
      switch (option) {
        case "to_customer":
          this.setCustomerEmail();
          this.option_description = "Send to the customer`s email";
          break;
        case "to_me":
          this.setMyEmail();
          this.option_description =
            "<span>Send to the account owner if an email is set in the <a target=\"_blank\" href='/app/account/settings'>account settings</a></span>";
          break;
        case "custom":
          this.setCustomEmail();
          this.option_description = "Enter Custom Email";
          break;
        default:
          this.resetOption();
      }
    },
    setCustomerEmail() {
      this.sendToCustomer = !this.sendToCustomer;
      this.sendToMe = false;
      this.hideTo = false;
      this.to = this.sendToCustomer ? "!customer" : "";
    },
    setMyEmail() {
      this.sendToMe = !this.sendToMe;
      this.sendToCustomer = false;
      this.hideTo = false;
      this.to = this.sendToMe ? this.getUser.email : "";
    },
    setCustomEmail() {
      this.hideTo = true;
    },
    resetOption() {
      this.to = "";
      this.hideTo = false;
      this.option_description =
        "You can send an email to yourself, a static email or to the customer`s email";
    },
    resetFormData() {
      this.to = "";
      this.subject = "";
      this.content = "";
    },
    async submitForm() {
      this.loading = true;
      this.$validator.validateAll().then((result) => {
        if (result) {
          this.$emit("save", {
            to: this.to,
            subject: this.subject,
            content: this.content,
            template: this.selectedTemplate ?? "",
          });
          this.resetModalData();
          this.closeModal();
        }
      });
      this.loading = false;
    },
    closeEmailModal() {
      this.resetModalData();
      this.resetFormData();
      this.closeModal();
    },
    onChangeTemplate(key) {
      if (this.template_option === this.empty_template) {
        this.selectedTemplate = "";
        this.$router.push({ name: "organization.email.template" });
      } else if (key) {
        const template = this.templates.find((item) => {
          return item.data.name === key;
        });
        this.selectedTemplate = template.id;
      }
    },
  },
};
</script>

<style lang="scss">
.editor {
  max-width: 100%;
}
.editor {
  .editor__content {
    min-height: 100px;
  }
}
</style>
