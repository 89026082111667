import statuses from './statuses'
import currencies from './currencies'

export default [
    {
        title: 'Order',
        model: 'order',
        options: [
            {
                id: 'total',
                title: 'Total',
                desc: '176.00',
                category: 'Order',
                model: 'order',
                type: 'number'
            },
            {
                id: 'status',
                title: 'Status',
                desc: 'Paid',
                category: 'Order',
                model: 'order',
                type: 'text',
                options: statuses
            },
            {
                id: 'order_number',
                title: 'Order number',
                desc: '729',
                category: 'Order',
                model: 'order',
                type: 'number'
            },
            {
                id: 'external_key',
                title: 'Key',
                desc: 'wc_order_58d2d042d13',
                category: 'Order',
                model: 'order',
                type: 'text'
            },
            {
                id: 'customer_note',
                title: 'Note',
                desc: 'Added during checkout',
                category: 'Order',
                model: 'order',
                type: 'text'
            },
            {
                id: 'email',
                title: 'Email',
                desc: 'jonh@doe.com',
                category: 'Order',
                model: 'order',
                type: 'text'
            },
            {
                id: 'channel_name',
                title: 'Channel',
                desc: 'web',
                category: 'Order',
                model: 'order',
                type: 'text'
            },
            {
                id: 'currency_code',
                title: 'Currency Code',
                desc: 'USD',
                category: 'Order',
                model: 'order',
                type: 'text',
                options: currencies
            },
            {
                id: 'type',
                title: 'Type',
                desc: 'order',
                category: 'Order',
                model: 'order',
                type: 'text'
            },
            {
                id: 'created',
                title: 'Created date',
                desc: '2019-04-02',
                model: 'order',
                type: 'date'
            }
        ]
    },
    {
        title: 'Shipping',
        model: 'shipping_address',
        options: [
            {
                id: 'given_name',
                title: 'First Name',
                desc: 'John',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'family_name',
                title: 'Last Name',
                desc: 'Doe',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'address_line_1',
                title: 'Address 1',
                desc: '205 E Houston St',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'address_line_2',
                title: 'Address 2',
                desc: '',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'locality',
                title: 'City',
                desc: 'New York',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'postal_code',
                title: 'Postcode',
                desc: '10002',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'administrative_area',
                title: 'State',
                desc: 'NY',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }, {
                id: 'organization',
                title: 'Company',
                desc: 'AutomationApp A/S',
                category: 'Shipping',
                model: 'shipping_address',
                type: 'text'
            }
        ]
    },
    {
        title: 'Billing',
        model: 'billing_address',
        options: [
            {
                id: 'given_name',
                title: 'First Name',
                desc: 'John',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'family_name',
                title: 'Last Name',
                desc: 'Doe',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'address_line_1',
                title: 'Address 1',
                desc: '150 E 14th St',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'address_line_2',
                title: 'Address 2',
                desc: '',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'locality',
                title: 'City',
                desc: 'New York',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'postal_code',
                title: 'Postcode',
                desc: '10003',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'administrative_area',
                title: 'State',
                desc: 'NY',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }, {
                id: 'organization',
                title: 'Company',
                desc: '5 Napkin Burger',
                category: 'Billing',
                model: 'billing_address',
                type: 'text'
            }
        ]
    },
    {
        title: 'Product',
        model: 'product',
        options: [
            {
                id: 'product_id',
                title: 'ID',
                desc: '72',
                category: 'Product',
                model: 'product',
                type: 'number'
            }, {
                id: 'sku',
                title: 'SKU',
                desc: 'p_21ed31',
                category: 'Product',
                model: 'product',
                type: 'text'
            }, {
                id: 'name',
                title: 'Name',
                desc: 'Simple T-Shirt',
                category: 'Product',
                model: 'product',
                type: 'text'
            }
        ]
    },
    {
        title: 'Payment',
        model: 'payment',
        options: [
            {
                id: 'status',
                title: 'Status',
                desc: 'New',
                category: 'Payment',
                model: 'payment',
                type: 'string'
            }, {
                id: 'method',
                title: 'Method',
                desc: 'Credit card',
                category: 'Payment',
                model: 'payment',
                type: 'text'
            }, {
                id: 'method_title',
                title: 'Method title',
                desc: 'Credit card',
                category: 'Payment',
                model: 'payment',
                type: 'text'
            }, {
                id: 'total',
                title: 'Total',
                desc: '1000',
                category: 'Payment',
                model: 'payment',
                type: 'number'
            }, {
                id: 'currency_code',
                title: 'Currency code',
                desc: 'USD',
                category: 'Payment',
                model: 'payment',
                type: 'text'
            }
        ]
    }
];