<template>
  <svg
    width="42"
    height="52"
    viewBox="0 0 42 42"
    class="mr-4"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
      <rect
        stroke="#00F"
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
        width="40"
        height="40"
        rx="4"
      />
      <rect fill="#DAE7FF" x="4" y="4" width="32" height="32" rx="2" />
      <rect
        stroke="#807FFF"
        stroke-width="2"
        fill="#FFF"
        stroke-linecap="round"
        stroke-linejoin="round"
        x="10"
        y="10"
        width="20"
        height="20"
        rx="2"
      />
      <path
        d="M24.138 16.293a1.162 1.162 0 011.543 0 .942.942 0 010 1.414l-6.549 6c-.426.39-1.117.39-1.543 0l-3.27-3a.942.942 0 010-1.414 1.162 1.162 0 011.544 0l2.499 2.293 5.776-5.293z"
        fill="#7F7FFF"
        fill-rule="nonzero"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: "BoxCheck",
};
</script>

<style scoped></style>
